import styled from 'styled-components'
import Button from '../../Forms/Button'
import { Input } from '../../Forms/Input'
import Navbar from '../Navbar'
const { Position } = Navbar

export const PositionElement = styled(Position).attrs(() => ({
  className: ``,
}))`
  border-left: 1px solid ${({ theme: { colors } }) => colors.primary.gold};
  padding: 0 1rem;
  :last-child {
    border: none;
  }
`
export const Profile = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-left: 1px solid ${({ theme: { colors } }) => colors.primary.gold};
  padding: 0 1rem;
`

export const Name = styled.h4`
  color: ${({ theme: { colors } }) => colors.silvers[5]};
  margin: 0 2rem 0 1rem;
`
export const ButtonAcoes = styled(Button)`
  border-color: ${({ theme: { colors } }) => colors.blacks[85]};
  margin: auto 0.25rem;
`

export const InputPesquisa = styled(Input)`
  border-color: ${({ theme: { colors } }) => colors.primary.gold};
  padding: 1.5rem;
  transition: all 0.3s linear;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${({ theme: { colors } }) => colors.primary.gold};
    filter: opacity(0.4);
  }
  :-ms-input-placeholder {
    color: ${({ theme: { colors } }) => colors.primary.gold};
    filter: opacity(0.4);
  }

  :focus {
    color: ${({ theme: { colors } }) => colors.primary.gold};
    border-color: ${({ theme: { colors } }) => colors.input.default.border_hover};
    box-shadow: 0px 0px 4px ${({ theme: { colors } }) => colors.input.default.shadow_hover};
  }
`
export const ButtonPesquisa = styled(Button)`
  padding: 1.5rem;

  border-color: ${({ theme: { colors } }) => colors.primary.gold};
`
export const Pesquisa = styled.div`
  padding: 0 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`
