import styled from 'styled-components'

import Button from '../Button'

export const Input = styled.input.attrs(() => ({
  className: `input`,
}))`
  background-color: ${({ theme: { colors } }) => colors.input.default.background};
  border-color: ${({ theme: { colors } }) => colors.input.default.border};
  color: ${({ theme: { colors } }) => colors.input.default.text};
  transition: all 0.3s linear;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${({ theme: { colors } }) => colors.input.default.text};
    filter: opacity(0.4);
  }
  :-ms-input-placeholder {
    color: ${({ theme: { colors } }) => colors.input.default.text};
    filter: opacity(0.4);
  }
  :focus,
  :hover {
    color: ${({ theme: { colors } }) => colors.input.default.textHover};
    border-color: ${({ theme: { colors } }) => colors.input.default.borderHover};
    box-shadow: 0px 0px 0px ${({ theme: { colors } }) => colors.input.default.shadowHover};
  }
`

export const ButtonPassword = styled(Button)`
  background-color: ${({ theme: { colors } }) => colors.blacks[85]};
  > svg {
    font-size: 1.2rem;
  }
`
