import React, { ReactNode } from 'react'

import * as S from './styled'

declare interface IProps {
  children?: ReactNode
  size?: 'normal' | 'medium' | 'large'
  color?: 'black' | 'dark' | 'light' | 'white' | 'primary' | 'link' | 'info' | 'success' | 'warning' | 'danger'
}

const Tag = ({ children, size, color }: IProps): JSX.Element => {
  return (
    <>
      <S.Tag color={color} size={size}>
        {children}
      </S.Tag>
    </>
  )
}

export default Tag
