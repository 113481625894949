import styled from 'styled-components'

export const Button = styled.button.attrs(() => ({
  className: 'button',
}))`
  background-color: ${({ theme: { colors } }) => colors.button.default.background};
  border-color: ${({ theme: { colors } }) => colors.button.default.border};
  color: ${({ theme: { colors } }) => colors.button.default.text};
  overflow: hidden;
  :focus,
  :hover {
    color: ${({ theme: { colors } }) => colors.button.default.textHover};
    border-color: ${({ theme: { colors } }) => colors.button.default.borderHover};
    box-shadow: 0px 0px 4px ${({ theme: { colors } }) => colors.button.default.shadowHover};
  }
  transition: all 0.3s linear;
`
