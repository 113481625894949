import styled from 'styled-components'

interface FieldProps {
  kind: string
}

interface ControleProps {
  icon: boolean
  loading: boolean
  fullwidth: boolean
}

export const Fields = styled.div.attrs(({ kind }: FieldProps) => ({
  className: `field has-${kind}`,
}))<FieldProps>``

export const Controls = styled.div.attrs(({ icon = false, loading = false, fullwidth = false }: ControleProps) => ({
  className: `control ${icon ? `has-icons-left` : ``} ${fullwidth ? `is-fullwidth` : ``} ${
    loading ? `is-loading` : ``
  }`,
}))<ControleProps>``
