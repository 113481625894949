import React, { ReactNode } from 'react'
import { Fields, Controls } from './styled'

declare interface FieldProps {
  children: ReactNode
  kind: string
}
declare interface ControleProps {
  children: ReactNode
  icon?: boolean
  loading?: boolean
  fullwidth?: boolean
}

const Field = ({ children, kind = '' }: FieldProps): JSX.Element => {
  return <Fields kind={kind}>{children}</Fields>
}
const Control = ({ icon = false, loading = false, children, fullwidth = false }: ControleProps): JSX.Element => {
  return (
    <Controls icon={icon} loading={loading} fullwidth={fullwidth}>
      {children}
    </Controls>
  )
}

export default { Field, Control }
