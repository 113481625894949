import styled from 'styled-components'

declare interface IProps {
  size?: 'normal' | 'medium' | 'large'
  color?: 'black' | 'dark' | 'light' | 'white' | 'primary' | 'link' | 'info' | 'success' | 'warning' | 'danger'
}
export const Tag = styled.span.attrs(({ size, color }: IProps) => ({
  className: `tag ${color ? `is-${color}` : ``} ${size ? `are-${size}` : ``}`,
}))<IProps>`
  margin: 0.25rem;
`
