import React, { ReactNode } from 'react'

import * as S from './styled'

declare interface LayoutProps {
  children?: ReactNode
}

const Container = ({ children }: LayoutProps): JSX.Element => {
  return (
    <>
      <S.Container data-testid="container">{children}</S.Container>
    </>
  )
}

export default Container
