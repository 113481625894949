import React from 'react'
import MenuProps from '../../interfaces/menu'

import { NavItem, NavMenu } from './styled'

const Menu = (): JSX.Element => {
  const menuItens: MenuProps[] = [
    {
      name: 'Home',
      link: '/',
      title: 'Página Principal',
    },
    {
      name: 'Canais',
      link: '/Canais',
      title: 'Ver todos os canais',
    },
    {
      name: 'Playlist',
      link: '/Playlist',
      title: 'Ver minha playlists',
    },
    {
      name: 'Meus Favoritos',
      link: '/Favotiros',
      title: 'Ver meu episódios favoritos',
    },
  ]

  return (
    <NavMenu id={`navbarmenu`}>
      {menuItens.map(({ name, link, title }) => (
        <NavItem key={name} href={link} title={title}>
          {name}
        </NavItem>
      ))}
    </NavMenu>
  )
}

export default Menu
