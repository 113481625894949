import React, { ReactNode } from 'react'

import * as S from './styled'

declare interface ButtonProps {
  children?: ReactNode
  onClick?: () => void
  loading?: boolean
  title?: string
}

const Button = ({ children, onClick, ...rest }: ButtonProps): JSX.Element => {
  return (
    <>
      <S.Button onClick={onClick} {...rest}>
        {children}
      </S.Button>
    </>
  )
}

export default Button
