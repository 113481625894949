import styled from 'styled-components'
import themeProps from '../../interfaces/theme'

export const NavMenu = styled.div.attrs(() => ({
  className: `navbar-menu`,
}))<{ theme: themeProps }>`
  height: 2rem;
  align-self: center;
`
export const NavItem = styled.a.attrs(() => ({
  className: `navbar-item`,
}))<{ theme: themeProps }>`
  color: ${({ theme: { colors } }) => colors.link.default.text};
  transition: color ease 0.5s;
  transition: background-color ease-in 0.8s;

  :hover,
  :active {
    color: ${({ theme: { colors } }) => colors.link.default.textHover} !important;
    background: ${({ theme: { colors } }) => colors.link.default.backgroundHover}!important;
    ::after {
      border: none;
    }
  }
  ::after {
    content: '';
    position: absolute;
    right: 0px;
    height: 15px;
    border-right: 1px solid ${({ theme: { colors } }) => colors.blacks[75]};
  }
  :last-child {
    ::after {
      border: none;
    }
  }
`
