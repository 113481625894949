import styled from 'styled-components'
import themeProps from '../../common/interfaces/theme'
import { Button, Box } from '../../common/components'

export const Container = styled.main`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export const Image = styled.figure.attrs(() => ({
  className: 'imagem',
}))`
  margin-bottom: 3rem;
  text-align: center;
`

export const ButtonLogin = styled(Button).attrs(() => ({}))`
  background-color: ${({ theme: { colors } }) => colors.primary.black};
  border: none;
  svg {
    margin-right: 0.5rem;
  }
`
export const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
`
export const Block = styled(Box)<{ theme: themeProps }>`
  background-color: ${({ theme: { colors } }) => colors.background.darkAlpha};
  padding: 2rem;
  border-radius: 25rem 25rem 1rem 10rem;
`
