import React, { ReactNode } from 'react'

import * as S from './styled'

declare interface LayoutProps {
  children?: ReactNode
}

const Box = ({ children, ...rest }: LayoutProps): JSX.Element => {
  return (
    <>
      <S.Box {...rest}>{children}</S.Box>
    </>
  )
}

export default Box
