import React, { ReactNode } from 'react'
import Images from '../../../assets/images'

import * as S from './styled'

declare interface PositionProps {
  position: 'start' | 'end' | string
  children?: ReactNode
}

declare interface IProps {
  children?: ReactNode
  active?: boolean
}

const Navbar = ({ children, active }: IProps): JSX.Element => {
  return (
    <>
      <S.Navbar className={`navbar ${active && ` is-active`}`} role="navigation" aria-label="main navigation">
        {children}
      </S.Navbar>
    </>
  )
}

const Burger = ({ active }: IProps): JSX.Element => {
  return (
    <>
      <S.Burger
        role="button"
        className={`navbar-burger ${active && ` is-active`}`}
        aria-label="menu"
        aria-expanded="false"
        data-target="navbarmenu">
        <span aria-hidden="true" />
        <span aria-hidden="true" />
        <span aria-hidden="true" />
      </S.Burger>
    </>
  )
}
const Brand = ({ active }: IProps): JSX.Element => {
  return (
    <>
      <S.Logo>
        <figure className="image">
          <img src={Images.logos.horizontalBgBlack} loading="lazy" alt="Logo" />
        </figure>
        <Burger active={active} />
      </S.Logo>
    </>
  )
}
const Position = ({ position = 'start', children, ...rest }: PositionProps): JSX.Element => {
  console.log(position)
  return (
    <S.PositionItem {...rest} position={`${position}`}>
      {children}
    </S.PositionItem>
  )
}

export default { Navbar, Brand, Position }
