import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { GlobalStyle } from '../common/assets/styles/global'
import Themes from '../common/assets/styles/themes'

import { Container } from '../common/components'
import { Routess } from './Routes'

import 'bulma/css/bulma.min.css'

function App(): JSX.Element {
  return (
    <>
      <ThemeProvider theme={Themes.dark}>
        <BrowserRouter>
          <GlobalStyle />
          <Container>
            <Routess />
          </Container>
        </BrowserRouter>
      </ThemeProvider>
    </>
  )
}

export default App
