import React, { useState } from 'react'
import { FaSearch } from 'react-icons/fa'
import { FiLogOut } from 'react-icons/fi'
import { RiUserSettingsFill } from 'react-icons/ri'
import { Navbar, Avatar, Menu, Form } from '../../index'
import { PositionElement, Pesquisa, InputPesquisa, ButtonAcoes, ButtonPesquisa, Profile, Name } from './styled'

const Header = (): JSX.Element => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false)
  return (
    <>
      <Navbar.Navbar active={menuOpen}>
        <Navbar.Brand />
        <PositionElement position={`start`}>
          <Menu />
        </PositionElement>
        <PositionElement position={'end'}>
          <Pesquisa className="is-touch">
            <Form.Field kind="addons">
              <Form.Control fullwidth>
                <InputPesquisa type="text" placeholder="Digite pra pesquisar" />
              </Form.Control>
              <Form.Control>
                <ButtonPesquisa title="Pesquisar um PodCast." loading={false}>
                  <FaSearch />
                </ButtonPesquisa>
              </Form.Control>
            </Form.Field>
          </Pesquisa>
          <Profile>
            <Avatar />
            <Name>Marcos de Castro</Name>
            <ButtonAcoes>
              <RiUserSettingsFill />
            </ButtonAcoes>
            <ButtonAcoes>
              <FiLogOut />
            </ButtonAcoes>
          </Profile>
        </PositionElement>
      </Navbar.Navbar>
    </>
  )
}

export default Header
