import styled from 'styled-components'
interface PositionProps {
  position: 'start' | 'end' | string
}
export const Navbar = styled.div`
  margin-bottom: 3rem;
  padding: 0.5rem;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.silvers[55]};
  background-color: ${({ theme: { colors } }) => colors.primary.black};
  box-shadow: 0 0rem 1.1rem ${({ theme: { colors } }) => colors.blacks[85]};
`
export const Burger = styled.a`
  color: ${({ theme: { colors } }) => colors.blacks[25]};

  > span {
    border-radius: 1.25rem;
    height: 2px;
  }
  :hover {
    color: ${({ theme: { colors } }) => colors.silvers[25]};

    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    > :nth-child(2) {
      width: 20px;
      left: auto;
    }
  }
  transition: color ease-in 0.5s;
`
export const PositionItem = styled.div.attrs(({ position = 'start' }: PositionProps) => ({
  className: `navbar-${position}`,
}))<PositionProps>``

export const Logo = styled.div.attrs(() => ({
  className: `navbar-brand`,
}))`
  padding: 0 1rem;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1000px) {
    border-right: none;
    background: red;
  }
`
