import React from 'react'
import { useNavigate } from 'react-router-dom'
import { HiOutlineMail } from 'react-icons/hi'
import { FaUnlock } from 'react-icons/fa'
import Images from '../../common/assets/images'
import { Input, InputPassword } from '../../common/components'

import { Container, Block, Image, ButtonGroup, ButtonLogin } from './styled'

const Login = (): JSX.Element => {
  const navigate = useNavigate()
  function clickLogin(): void {
    navigate('/Home')
  }
  return (
    <Container>
      <Block>
        <Image onLoad={() => console.log('imagem carregada')}>
          <img src={Images.logos.verticalBgBlack} />
        </Image>
        <Input type="email" placeholder="Digite seu e-mail" icon={<HiOutlineMail />} />
        <InputPassword />
        <ButtonGroup>
          <ButtonLogin onClick={() => clickLogin()}>
            <FaUnlock /> Login
          </ButtonLogin>
        </ButtonGroup>
      </Block>
    </Container>
  )
}

export default Login
