import React from 'react'
import { FaUser } from 'react-icons/fa'

import { Avatar } from './styled'

const Default = (): JSX.Element => {
  return (
    <>
      <Avatar>
        <FaUser />
      </Avatar>
    </>
  )
}

export default Default
