import styled from 'styled-components'

export const Container = styled.main.attrs(() => ({
  className: '',
}))`
  height: 100vh;
  /*display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;*/
`
