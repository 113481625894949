import React from 'react'

import { Header } from '../../../common/components'
import { Container } from './styled'

const HomePage = (): JSX.Element => {
  return (
    <Container>
      <Header />
    </Container>
  )
}

export default HomePage
