import styled from 'styled-components'

export const Avatar = styled.div`
  width: 50px;
  height: 50px;
  background-color: ${({ theme: { colors } }) => colors.blacks[95]};
  border-radius: 50%;
  border: 1px solid ${({ theme: { colors } }) => colors.blacks[90]};
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  > * {
    width: 50px;
    font-size: 2.5rem;
    margin-top: 1rem;
  }
`
