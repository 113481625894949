import imagesProps from '../../interfaces/images'

// Images
import horizontalBgBlack from './logo_horizontal_fundo_escuro.svg'
import horizontalBgWhite from './logo_horizontal_fundo_claro.svg'
import verticalBgBlack from './logo_vertical_fundo_escuro.svg'
import verticalBgWhite from './logo_vertical_fundo_claro.svg'

// Animations
import audio from './animations/audioWave.json'
import podcastGirl from './animations/podcastGirl.json'
import podcastMen from './animations/podcastMen.json'
import recording from './animations/recordingButton.json'
import error from './animations/error404_2.json'

const Images: imagesProps = {
  logos: {
    horizontalBgBlack,
    horizontalBgWhite,
    verticalBgBlack,
    verticalBgWhite,
  },
  animations: {
    audio,
    podcastGirl,
    podcastMen,
    recording,
    error,
  },
}

export default Images
