import React, { HTMLInputTypeAttribute, ReactElement, useState } from 'react'
import { MdPassword } from 'react-icons/md'
import { HiEyeOff, HiEye } from 'react-icons/hi'
import { IconType } from 'react-icons/lib'
import * as S from './styled'
import { Form } from '../../'

declare interface InputProps {
  icon?: ReactElement<IconType>
  placeholder?: string
  loading?: boolean
  type?: HTMLInputTypeAttribute
}

export const Input = ({ type, loading = false, icon, ...props }: InputProps): JSX.Element => {
  const existIcon = icon && true
  return (
    <Form.Field kind={`addon`}>
      <Form.Control icon={existIcon} loading={loading}>
        <S.Input type={type} {...props} />
        {existIcon && <span className="icon is-small is-left">{icon}</span>}
      </Form.Control>
    </Form.Field>
  )
}

export const Password = ({ ...props }: InputProps): JSX.Element => {
  const [typePassword, setTypePasswrod] = useState<HTMLInputTypeAttribute>('password')
  function handlePasswordView(): void {
    setTypePasswrod(typePassword === 'text' ? 'password' : 'text')
  }
  return (
    <Form.Field kind={`addons`}>
      <Form.Control icon={true}>
        <S.Input type={typePassword} placeholder={`Digite sua senha.`} {...props} />
        <span className="icon is-small is-left">
          <MdPassword />
        </span>
      </Form.Control>
      <Form.Control>
        <S.ButtonPassword onClick={() => handlePasswordView()}>
          {typePassword === 'text' ? <HiEye /> : <HiEyeOff />}
        </S.ButtonPassword>
      </Form.Control>
    </Form.Field>
  )
}
